import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import {Bar} from 'react-chartjs-2';
import Layout from "../components/Layout"

export default () => {

const data = useStaticQuery(graphql`
    query FactsandFiguresQuery {
    allFactsandfiguresJson {
    edges {
        node {
          id
          title
          type
          labels
          datasets {
            type
            label
            backgroundColor
            pointBackgroundColor
            data
          }
        }
      }
    }
  }
  `)

  const graphs = data.allFactsandfiguresJson.edges;

  const  options =  {
          maintainAspectRatio: false,
          legend:{ position: 'bottom'},
          tooltips: {
          titleFontSize: 16,
          bodyFontSize: 15,
          bodySpacing: 6,
          bodyFontColor: 'rgb(175,185,185)',
          xPadding: 10,
          yPadding:10,
          callbacks: {
              label: function(tooltipItem, data) {
                  return data.datasets[tooltipItem.datasetIndex].label + ': ' +  tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "); },
          },
          },
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero:true,
                      maxTicksLimit:16,
                      callback: function(label, index, labels) { return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "); },
                  }
              }],
              xAxes: [{
                  ticks: {
                      beginAtZero:true,
                      min: 0,
                      maxTicksLimit:16
                  }
              }]
          }
  }



  return ( 
    <Layout>
    <div className="w-100 cf">
    <div className="ph3 ph4-ns pv1">
    <div className="mw9 center">

    <div className="w-100 f4 fw5 pb2 bb b--black-10 mb2 pt4">Facts and Figures</div>
    <div className="w-100 f7 pb2 mb4">* estimates / Note: click any legend below the graph to filter the view options.</div>

    { graphs.map(({ node }) => {
                return ( 
                  <div key={node.id} className="w-100 mw8 tl">
                  <div className="w-100 f5 fw5 pb2 bb b--black-10 mb4">{node.title}</div>
                    <div className="chart-container relative w-100 pb5 pr3 vh-80 vh-50-ns">
                    <Bar data={node} options={ options }/>
                    </div>
                  </div>
                )
            })
    }
      
    </div>
    </div>
    </div>
    </Layout>
  ) 

}


